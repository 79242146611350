<template>
  <v-card
    flat
    outlined
    class="tco-product-block primary--text h-100 text-center d-flex flex-column align-center justify-space-between"
  >
    <!-- tco product not choosed -->
    <template v-if="!selectedProduct && !loading">
      <div v-if="active" class="avaiable-banner">
        {{ $t("tco.perks.blocks.availableNow") }}
      </div>
      <v-img
        img
        src="/img_layout/tco/tco-discount.png"
        alt="Prodotto oro"
        max-width="250"
        max-height="250"
        contain
      />
      <v-card-title class="text-center text-h2 line-height-13">
        {{ $t("tco.perks.blocks.tcoProduct.notSelected.title") }}
      </v-card-title>
      <v-card-text class="primary--text text-body-1">
        {{ $t("tco.perks.blocks.tcoProduct.notSelected.descr") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" depressed :to="{ name: 'TcoChooseProduct' }">
          {{ $t("tco.perks.blocks.tcoProduct.notSelected.btn") }}
        </v-btn>
      </v-card-actions>
    </template>

    <!-- tco product alredy choosed -->
    <template v-else-if="selectedProduct && !loading">
      <v-card-title class="text-center text-h2 line-height-13">
        {{ $t("tco.perks.blocks.tcoProduct.selected.title") }}
      </v-card-title>
      <v-card-text class="primary--text">
        <div class="oro-product d-flex align-center justify-center">
          <img
            :src="productImageSrc"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center"
            :width="90"
            :height="90"
            :alt="'Immagine' + selectedProduct.name"
            :title="
              selectedProduct.name +
                ' (' +
                selectedProduct.codInt +
                '-' +
                selectedProduct.codVar +
                ')'
            "
          />
          <div>
            <div class="sconto">-20%</div>

            <span class="d-block name font-weight-bold">
              {{ selectedProduct.name }}
            </span>
            <span class="d-block short_descr">
              {{ selectedProduct.shortDescr }}
            </span>
            <span class="d-block descr">
              {{ selectedProduct.description }}
            </span>
          </div>
        </div>
        <span class="text-body-1">
          {{ $t("tco.perks.blocks.tcoProduct.selected.descr") }}
        </span>
      </v-card-text>
      <v-card-actions>
        <!-- v-if="isShowProductsByDate || !selectedProduct" -->
        <v-btn
          v-if="isShowProductsByDate"
          color="primary"
          depressed
          :to="{ name: 'TcoChooseProduct' }"
        >
          {{ $t("tco.perks.blocks.tcoProduct.notSelected.btn") }}
        </v-btn>
        <div v-else>
          <img class="check" src="/img_layout/tco/tco-check.svg" alt="check" />
        </div>
      </v-card-actions>
    </template>
  </v-card>
</template>
<style lang="scss">
.tco-product-block {
  background-image: url("/img_layout/tco/tco-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .v-card__title {
    font-family: $heading-font-family;
    font-size: 20px;
  }
  .oro-product {
    padding: 5px;
    border: 1px solid $gold;
    border-radius: $border-radius-root;
    margin-bottom: 15px;
  }
  .sconto {
    font-size: 18px;
    background: $gold;
    color: $promo;
    padding: 3px 8px;
    width: fit-content;
    margin: 0px auto;
  }
}
</style>
<script>
import tigrosCardOroService from "@/service/tigrosCardOroService";

import get from "lodash/get";

export default {
  name: "TcoOroProductBlock",
  data() {
    return {
      modalOpen: false,
      loading: true,
      selectedProduct: null,
      showProductsByDate: global.config.showProductsByDate
    };
  },
  props: {
    active: { type: Boolean, default: false }
  },
  computed: {
    productImageSrc() {
      return get(this.selectedProduct, "media[0].small", null);
    },
    isShowProductsByDate() {
      let currentDate = new Date().getTime();

      let minDate = new Date(this.showProductsByDate.minDate).getTime();
      let maxDate = new Date(this.showProductsByDate.maxDate).getTime();
      //IN CONFIG.JS SET THE NEXT DAY AS MAX DATE

      return minDate <= currentDate && currentDate <= maxDate ? true : false;
    }
  },
  methods: {
    async getOroProduct() {
      let res = await tigrosCardOroService.getProduct();
      if (res && Object.keys(res).length > 0) {
        this.selectedProduct = res;
      }
      this.loading = false;
    }
  },
  created() {
    if (global.config.showProductsByDate.show) {
      this.getOroProduct();
    }
  }
};
</script>
